/** Image Gallery actions.
* @module actions/content_types/get_pages
*/

import { GET_ORDER_ITEMS } from '../constants/ActionTypes';

/**
* Get FAQ items.
* @function getOrderItems
* @returns {Object} Images action.
*/
export function getOrderItems(scope=null, depth=1) {
  const path = (scope!=null) ? `path.query=${encodeURIComponent(scope)}` : "";
  const query = (scope!=null) ? `&${path}&path.depth=${depth}` : "";
  return {
    type: GET_ORDER_ITEMS,
    request: {
      op: 'get',
      path: `/@search?portal_type=glisco.plone.ecommerce.orderitem&fullobjects=1${query}`,
    },
 };
}

