/**
 * Gallery Images reducer.
 * @module reducers/content_types/pages
 */

 import { map } from 'lodash';

 import { GET_ORDER_ITEMS } from '../constants/ActionTypes';

 const initialState = {
   error: null,
   loaded: false,
   loading: true,
   orderItems: []
 };

 /**
  * Faq reducer.
  * @function products
  * @param {Object} state Current state.
  * @param {Object} action Action to be handled.
  * @returns {Object} New state.
  */
 export default function order_items(state = initialState, action = {}) {
   switch (action.type) {
     case `${GET_ORDER_ITEMS}_PENDING`:
       return {
         ...state,
         error: "LOADING ORDER ITEMS",
         loading: true,
         loaded: false,
         orderItems: [],
       };
     case `${GET_ORDER_ITEMS}_SUCCESS`:
        // console.log(">>>>>>>>>>>>>>>>>>>>>>> ORDER ITEMS REDUCER <<<<<<<<<<<<<<<<<<<<<<<");
        // console.log(JSON.stringify(action.result, null, 2));
        const configData = (configB64) => {
          const jsonData = Buffer.from(configB64, 'base64').toString('binary');
          return JSON.parse(jsonData);
        }
        const results = action.result.items.map((item) => {
            const orderId = (item.parent["@id"]!=null) ? item.parent["@id"].split("/").slice(-1)[0] : "";
            const clientId = (item.parent["@id"]!=null) ? item.parent["@id"].split("/").slice(-2)[0] : "";
            const siteId = (item.parent["@id"]!=null) ? item.parent["@id"].split("/").slice(-4)[0] : "";
            return {
                uid: item.UID,
                orderItemId: item.id,
                order: orderId,
                client: clientId,
                url: `/${siteId}/Members/${clientId}/${orderId}/${item.id}`,
                title: item.title,
                description: item.description,
                created: item.created,
                modified: item.modified,
                configData: item.orderItemConfigData,
                status: item.status.title,
                location: configData(item.orderItemConfigData).location
            }
        });


        // console.log(">>>>>>>>>>>>>>>>>>>>>>> ORDER ITEMS REDUCER RESULTS <<<<<<<<<<<<<<<<<<<<<<<");

        // console.log(JSON.stringify(results, null, 2));

        return {
            ...state,
            error: "DATA RECEIVED SUCCESSFULLY",
            loaded: true,
            loading: false,
            orderItems: results
        };

     case `${GET_ORDER_ITEMS}_FAIL`:
       return {
         ...state,
         error: action.error,
         loading: false,
         loaded: false,
         orderItems: [],
       };
     default:
       return state;
   }
 }
