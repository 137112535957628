import React, { Component }  from 'react';
import { DefaultView } from "@plone/volto/components";

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import jwtDecode from 'jwt-decode';
import { getUser } from '@plone/volto/actions';

import 'react-tabs/style/react-tabs.css';
import 'semantic-ui-less/semantic.less';


// import './picture-gallery.less';

class ReactOrderItemView extends Component {

//   state = { 
//     modalIsOpen: false,
//     currentImage: 0 
//   }


  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  // static propTypes = {
  //   // getImagesFromPlone: PropTypes.func.isRequired,
  //   userId: PropTypes.string.isRequired,
  //   getUser: PropTypes.func.isRequired,
  // };

  /**
   * Constructor
   * @method constructor
   * @param {Object} props Component properties
   * @constructs Navigation
   */
   constructor(props) {
    super(props);

    this.blockClass = "ReactOrderView";
    this.ref = React.createRef();

  }
  

  componentDidMount = () => {
    // window.addEventListener('scroll', this.handleScroll);
    // console.log("+++++++++++++++++++++++++++++++++++++++++");
    // console.log("+++++++++++++++++++++++++++++++++++++++++");
    // console.log("ReactOrderView did mount");
    // this.props.getUser(this.props.userId);
    // this.props.getProducts();
    // const searchScope=`/${this.props.site}/Members/${this.props.client}/${this.props.order.id}`; 
    // const searchDepth = 2;
    // this.props.getOrderItems(searchScope, searchDepth);
    // console.log("+++++++++++++++++++++++++++++++++++++++++");
    // console.log("+++++++++++++++++++++++++++++++++++++++++");
  }

  componentWillUnmount = () => {
  }

  configData = () => {
    const jsonData = Buffer.from(this.props.content.orderItemConfigData, 'base64').toString('binary');
    return JSON.parse(jsonData);
  }

  orderUrl = () => {
    const url = "/" + this.props.content.parent["@id"].split("/").slice(4).join("/");
    // console.log(`PARENT is ${this.props.content.parent["@id"]}, which we're transforming to ${url}`);
    // console.log(this.props.content);
    // const url = this.props.content.parent["@id"];
    // return "https://google.com";
    return url;
  }

  orderClient = () => {
    const url = "/" + this.props.content.parent["@id"].split("/").slice(5,6).join('');
    // console.log(`PARENT is ${this.props.content.parent["@id"]}, which we're transforming to ${url}`);
    // console.log(this.props.content);
    // const url = this.props.content.parent["@id"];
    // return "https://google.com";
    return url;
  }

  geoCoordinatesTable = (geoCoordinates) => {
    let index=1;
    return geoCoordinates.map((c) => {
        return <tr key={index}>
          <td data-label="Name">Coord {index++}</td>
          <td data-label="Age">{c.lat}</td>
          <td data-label="Job">{c.lng}</td>
        </tr>
    });
  }


  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
   render() {

    const orderStatus = "order-status " + this.props.content.status.token;

    const configuration = this.configData();

    return (
      <div className="marginContainer">
        <h1>ORDER {this.props.content.title}</h1>
        <h2 className={orderStatus}>{configuration.location} - {this.props.content.status.title}</h2>
        <h2>Part of <a href={this.orderUrl()}>{this.props.content.parent.title}</a> by {this.orderClient()}</h2>

        <h3>Product Information</h3>

        <div className="productInfo">
          <div className="productName">Product: {configuration.productInfo.title}</div>
          <div className="purchasePrice">Purchase Price: {configuration.productInfo.price}</div>
          <div className="purchaseArea">Area: {configuration.area}</div>
          <div className="observationDates">Dates: {JSON.stringify(configuration.dates, null, 2)}</div>
          {/* <div className="observationDates">Dates: {configuration.dates.map((d) => {
            try {
              return d.split("T")[0]
            } catch (ex) {
              const allDates = JSON.stringify(configuration.dates, null, 2);
              return allDates;
            }
          }).join(", ")}</div> */}
        </div>

        <div className="coordinates">
          <h3>Coordinates</h3>

          <table className="ui celled table">
            <thead>
              <tr><th>Name</th>
              <th>Lat</th>
              <th>Lng</th>
            </tr></thead>
            <tbody >
              {this.geoCoordinatesTable(configuration.geoCoordinates)}
            </tbody>
          </table>
        </div>

        <h3>Technical Data</h3>
        
        <pre>
          {JSON.stringify(configuration, null, 2)}
        </pre>
        {/* <DefaultView {...this.props}/> */}
      </div>  
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  // console.log(">>>>>>>>>>>>>>>>>>> ORDER VIEW mapStateToProps: ");
  // console.log(JSON.stringify(state.orders, null, 2));
  const shouldRedirect = (user) => {
    try {
      let roles = user.roles.map(r => r.toLowerCase());
      let allowAccess = ["manager", "site administrator", "store manager"].reduce(function (previousValue, currentValue) {
        return previousValue || roles.includes(currentValue);
      }, false);
      return !allowAccess;
    } catch(ex) {
      // This means we don't have user data, particulaly about their roles. 
      // We may or may not have to redirect, but we don't know yet.
      // Returning undefined, as simply true/false is not sufficient yet
      return undefined;
    }
  }

  const userId = state.userSession.token ? jwtDecode(state.userSession.token).sub : undefined;

  const redirect = (userId == undefined) ? true : shouldRedirect(state.users.user);

  const known = (redirect != undefined);

  const propsStruct = {
    ...ownProps.data,
    content : state.content.data
  }

  return propsStruct;
}

const mapDispatchToProps = {
  getUser : getUser,
}

export default
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ReactOrderItemView);
