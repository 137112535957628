/** Image Gallery actions.
* @module actions/content_types/get_pages
*/

import { GET_IMAGES } from '../constants/ActionTypes';

/**
* Get FAQ items.
* @function getPages
* @returns {Object} Images action.
*/
export function getImages(tag) {
  return {
    type: GET_IMAGES,
    request: {
      op: 'get',
      path: `/@search?portal_type=Image&Subject=${tag}&fullobjects=1`,
    },
 };
}

