/** Image Gallery actions.
* @module actions/content_types/get_pages
*/

import { GET_REVIEWS } from '../constants/ActionTypes';

/**
* Get FAQ items.
* @function getPages
* @returns {Object} Images action.
*/
export function getReviews(tag) {
  let query;
  if (tag!==null && tag.length>0) {
    query = `/@search?portal_type=glisco.plone.ecommerce.review&Subject=${tag}&fullobjects=1`
  } else {
    query = `/@search?portal_type=glisco.plone.ecommerce.review&fullobjects=1`
  }

  // console.log(">>>>>> USING QUERY=" + query);
  return {
    type: GET_REVIEWS,
    request: {
      op: 'get',
      path: query,
    },
 };
}

