import React, { Component }  from 'react';
import { DefaultView } from "@plone/volto/components";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Card, Icon, Image } from 'semantic-ui-react'


import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import jwtDecode from 'jwt-decode';
import { getUser } from '@plone/volto/actions';
import { getProducts, getOrders, getPois } from 'actions';

import { getBaseUrl } from '@plone/volto/helpers';
import config from '@plone/volto/registry';

import 'react-tabs/style/react-tabs.css';
import 'semantic-ui-less/semantic.less';


// import './picture-gallery.less';

class ReactStoreView extends Component {

//   state = { 
//     modalIsOpen: false,
//     currentImage: 0 
//   }


  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    // getImagesFromPlone: PropTypes.func.isRequired,
    userId: PropTypes.string.isRequired,
    getUser: PropTypes.func.isRequired,
  };

  /**
   * Constructor
   * @method constructor
   * @param {Object} props Component properties
   * @constructs Navigation
   */
   constructor(props) {
    super(props);

    this.blockClass = "ReactStoreView";
    this.ref = React.createRef();
    this.dataLoaded = false;
  }

  componentDidMount = () => {
    this.dataLoaded = false;
    this.props.getUser(this.props.userId);
    
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {

    if (prevProps.userId !== undefined) {
      if (!this.dataLoaded) {
        this.dataLoaded=true;
        this.props.getProducts();
        this.props.getOrders();
        this.props.getPois();
      }
    } else {
      this.redirect();
    }
  }
  componentWillUnmount = () => {
  }

  

  redirect = () => {
    if (this.props.redirect != undefined && this.props.redirect) {
      // browser code
      if (typeof window !== "undefined") {
        window.location.replace(this.props.content.storeUrl);
      }
      
      return "YES"
    } else if (this.props.redirect != undefined && !this.props.redirect){
      return "NO";
    } else {
      return "DON'T KNOW";
    }
    
    
  }

  productsCards = () => {
    return Array.isArray(this.props.products) ? this.props.products.map((p) => {
      const productUrl = p.url.split("/").slice(4).join("/"); 
      // const productUrl = p.url; 
      return <Card href={productUrl}>
              <Image src={p.image} wrapped ui={false} />
              <Card.Content>
                <Card.Header>{p.title}</Card.Header>
                <Card.Meta>
                  <span className='date'>{p.classifications.join(", ")}</span>
                </Card.Meta>
                <Card.Description>
                {p.description}
                </Card.Description>
              </Card.Content>
              <Card.Content extra>
                  <Icon name='users' />
                  Edit
              </Card.Content>
            </Card>
    }) : []
  }

  ordersCards = () => {
    return Array.isArray(this.props.orders) ? this.props.orders.map((o) => {
      const orderStatus = o.status.toLowerCase().replace(" ", "-");
      const cardMetaClass = 'status ' + orderStatus;
      const orderColours = {
        "new" : "blue",
        "in-progress": "orange",
        "cancelled" : "red",
        "fullfilled": "green"
      };
      const created = new Date(o.created);
      const day = (created.getDate() < 10 ? '0' : '') + created.getDate();
      const month = created.toLocaleString('default', { month: 'short' });
      const orderUrl = "/Members/" + o.client + "/" + o.url;

      return <Card href={orderUrl} color={orderColours[orderStatus]} >
              <Image src={o.image} wrapped ui={false} />
              <Card.Content data-status={orderStatus}>
                <Card.Header>{o.title}</Card.Header>
                <Card.Meta>
                  <span className={cardMetaClass}>{o.status}</span>
                </Card.Meta>
                <Card.Description>
                {o.description}
                </Card.Description>
              </Card.Content>
              <Card.Content extra>
                  <Icon name='users' />
                  <span className="date">{day}/{month}/{created.getFullYear()}</span>
              </Card.Content>
            </Card>
    }) : []
  }

  poiCards = () => {
    return Array.isArray(this.props.pois) ? this.props.pois.map((poi) => {
      // console.log("POI CARD ");
      // console.log(JSON.stringify(poi, null, 2));
      // const orderStatus = poi.status.toLowerCase().replace(" ", "-");
      // const cardMetaClass = 'status ' + orderStatus;
      // const orderColours = {
      //   "new" : "blue",
      //   "in-progress": "orange",
      //   "cancelled" : "red",
      //   "fullfilled": "green"
      // };
      const created = new Date(poi.created);
      const day = (created.getDate() < 10 ? '0' : '') + created.getDate();
      const month = created.toLocaleString('default', { month: 'short' });
      const url = "/" + poi.url.split("/").slice(4).join("/");
      // const url = poi.url;
      return <Card href={url} >
              <Image src={poi.image} wrapped ui={false} />
              <Card.Content>
                <Card.Header>{poi.title}</Card.Header>
                <Card.Meta>
                  {/* <span className={cardMetaClass}>{o.status}</span> */}
                </Card.Meta>
                <Card.Description>
                {poi.description}
                </Card.Description>
              </Card.Content>
              <Card.Content extra>
                  <Icon name='users' />
                  <span className="date">{day}/{month}/{created.getFullYear()}</span>
              </Card.Content>
            </Card>
    }): []
  }

  geoCoordinatesTable = (geoCoordinates) => {
    let index=1;
    return geoCoordinates.map((c) => {
        return <tr>
          <td data-label="Name">Coord {index++}</td>
          <td data-label="Age">{c.lat}</td>
          <td data-label="Job">{c.lng}</td>
        </tr>
    });
  }


  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
   render() {

    return (
      <div className="store ui.container">
        <h1>Store Management</h1>
        {/* <DefaultView {...this.props}/> */}
        <div className="storeRedirect">If you wish to visit the store, click <a href={this.props.content.storeUrl}>here</a></div>
        
        <Tabs>
          <TabList>
            <Tab>Products</Tab>
            <Tab>Points of Interest</Tab>
            <Tab>Orders</Tab>
            <Tab>Clients</Tab>
          </TabList>

          <TabPanel>
            <h2>Products</h2>
            <div className="cardContainer">
              {this.productsCards()}
            </div>
          </TabPanel>
          <TabPanel>
            <h2>Points of Interest</h2>
            <div className="cardContainer">
              {this.poiCards()}
            </div>
            
          </TabPanel>
          <TabPanel>
            <h2>Orders</h2>
            <div className="cardContainer">
              {this.ordersCards()}
            </div>  
          </TabPanel>
          <TabPanel>
            <h2>Clients - Work In Progress (Dummy Data)</h2>
            <Card>
              <Image src='https://react.semantic-ui.com/images/avatar/large/matthew.png' wrapped ui={false} />
              <Card.Content>
                <Card.Header>Matthew</Card.Header>
                <Card.Meta>
                  <span className='date'>Joined in 2015</span>
                </Card.Meta>
                <Card.Description>
                  Matthew is a musician living in Nashville.
                </Card.Description>
              </Card.Content>
              <Card.Content extra>
                <a>
                  <Icon name='user' />
                  22 Friends
                </a>
              </Card.Content>
            </Card>
          </TabPanel>
        </Tabs>
      </div>  
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const shouldRedirect = (user) => {
    try {
      let roles = user.roles.map(r => r.toLowerCase());
      let allowAccess = ["manager", "site administrator", "store manager"].reduce(function (previousValue, currentValue) {
        return previousValue || roles.includes(currentValue);
      }, false);
      return !allowAccess;
    } catch(ex) {
      // This means we don't have user data, particulaly about their roles. 
      // We may or may not have to redirect, but we don't know yet.
      // Returning undefined, as simply true/false is not sufficient yet
      return undefined;
    }
  }

  const userId = state.userSession.token ? jwtDecode(state.userSession.token).sub : undefined;

  const redirect = (userId == undefined) ? true : shouldRedirect(state.users.user);

  const known = (redirect != undefined);

  const propsStruct = {
    ...ownProps.data,
    user: state.users.user,
    userId: userId,
    redirect: redirect,
    known: known ? "KNOWN" : "NOT KNOWN",
    controlString: (known && redirect) ? "YES" : "NO",
    products: state.products.products,
    orders: state.orders.orders,
    pois: state.pois.pois
  }

  // console.log(propsStruct);

  return propsStruct;
}

const mapDispatchToProps = {
  getUser : getUser,
  getProducts : getProducts,
  getOrders : getOrders,
  getPois: getPois
}

export default
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ReactStoreView);
