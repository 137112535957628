/*
This file is autogenerated. Don't change it directly.
Instead, change the "addons" setting in your package.json file.
*/

const projectConfigLoader = require('@package/config');
import eeacmsvoltoBlocksForm from '@eeacms/volto-blocks-form';
import eeacmsvoltoColumnsBlock from '@eeacms/volto-columns-block';
import eeacmsvoltoResizeHelper from '@eeacms/volto-resize-helper';
import eeacmsvoltoBlockStyle from '@eeacms/volto-block-style';
import gliscoowlCustomBlocks from '@glisco/owl-custom-blocks';

const addonsInfo = {"@glisco/owl-custom-blocks":{"modulePath":"/Users/joemedicis/Development/Clients/OWL/OWL-Volto/owl-cms/src/addons/custom-blocks/src","packageJson":"/Users/joemedicis/Development/Clients/OWL/OWL-Volto/owl-cms/src/addons/custom-blocks/package.json","version":"0.1.0","isPublishedPackage":false,"name":"@glisco/owl-custom-blocks","addons":[]},"@eeacms/volto-blocks-form":{"name":"@eeacms/volto-blocks-form","version":"2.0.0","isPublishedPackage":true,"modulePath":"/Users/joemedicis/Development/Clients/OWL/OWL-Volto/owl-cms/node_modules/@eeacms/volto-blocks-form/src","packageJson":"/Users/joemedicis/Development/Clients/OWL/OWL-Volto/owl-cms/node_modules/@eeacms/volto-blocks-form/package.json","addons":[]},"@eeacms/volto-columns-block":{"name":"@eeacms/volto-columns-block","version":"4.4.14","isPublishedPackage":true,"modulePath":"/Users/joemedicis/Development/Clients/OWL/OWL-Volto/owl-cms/node_modules/@eeacms/volto-columns-block/src","packageJson":"/Users/joemedicis/Development/Clients/OWL/OWL-Volto/owl-cms/node_modules/@eeacms/volto-columns-block/package.json","addons":[]},"@eeacms/volto-block-style":{"name":"@eeacms/volto-block-style","version":"3.5.16","isPublishedPackage":true,"modulePath":"/Users/joemedicis/Development/Clients/OWL/OWL-Volto/owl-cms/node_modules/@eeacms/volto-block-style/src","packageJson":"/Users/joemedicis/Development/Clients/OWL/OWL-Volto/owl-cms/node_modules/@eeacms/volto-block-style/package.json","addons":["@eeacms/volto-resize-helper"]},"@eeacms/volto-resize-helper":{"name":"@eeacms/volto-resize-helper","version":"0.2.8","isPublishedPackage":true,"modulePath":"/Users/joemedicis/Development/Clients/OWL/OWL-Volto/owl-cms/node_modules/@eeacms/volto-resize-helper/src","packageJson":"/Users/joemedicis/Development/Clients/OWL/OWL-Volto/owl-cms/node_modules/@eeacms/volto-resize-helper/package.json","addons":[]}};
export { addonsInfo };

const safeWrapper = (func) => (config) => {
  const res = func(config);
  if (typeof res === 'undefined') {
    throw new Error("Configuration function doesn't return config");
  }
  return res;
}

const projectConfig = (config) => {
  return typeof projectConfigLoader.default === "function" ? projectConfigLoader.default(config) : config;
}

const load = (config) => {
  const addonLoaders = [eeacmsvoltoBlocksForm, eeacmsvoltoColumnsBlock, eeacmsvoltoResizeHelper, eeacmsvoltoBlockStyle, gliscoowlCustomBlocks];
  if(!addonLoaders.every((el) => typeof el === "function")) {
    throw new TypeError(
      'Each addon has to provide a function applying its configuration to the projects configuration.',
    );
  }
  return projectConfig(addonLoaders.reduce((acc, apply) => safeWrapper(apply)(acc), config));
};
export default load;
