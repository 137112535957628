/**
 * Gallery Images reducer.
 * @module reducers/content_types/pages
 */

 import { map } from 'lodash';

 import { GET_PRODUCTS } from '../constants/ActionTypes';

 const initialState = {
   error: null,
   loaded: false,
   loading: true,
   products: []
 };

 /**
  * Faq reducer.
  * @function products
  * @param {Object} state Current state.
  * @param {Object} action Action to be handled.
  * @returns {Object} New state.
  */
 export default function products(state = initialState, action = {}) {
   switch (action.type) {
     case `${GET_PRODUCTS}_PENDING`:
       return {
         ...state,
         error: "LOADING PAGES",
         loading: true,
         loaded: false,
         products: [],
       };
     case `${GET_PRODUCTS}_SUCCESS`:
        // console.log(">>>>>>>>>>>>>>>>>>>>>>> PRODUCTS REDUCER <<<<<<<<<<<<<<<<<<<<<<<");
        const results = action.result.items.map((item) => {
            return {
                uid: item.UID,
                url: item["@id"],
                partNumber: item.partNumber,
                title: item.title,
                description: item.description,
                image: item.image==null ? null : item.image.download,
                price: item.price,
                classifications: item.classification==null ? [] : item.classification.map(c => c.token),
                store: item.parent["@id"]
            }
        });
        // console.log(JSON.stringify(results, null, 2));
        return {
            ...state,
            error: "DATA RECEIVED SUCCESSFULLY",
            loaded: true,
            loading: false,
            products: results
        };

     case `${GET_PRODUCTS}_FAIL`:
       return {
         ...state,
         error: action.error,
         loading: false,
         loaded: false,
         products: [],
       };
     default:
       return state;
   }
 }
