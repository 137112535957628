import { ReactStoreView, 
         ReactOrderView, 
         ReactOrderItemView, 
         ReactProductView, 
         ReactPointOfInterestView,
         GoogleMapsStoreView } from './components';

// All your imports required for the config here BEFORE this line
import '@plone/volto/config';

import {
  customBlocks as customBlocks,
} from '@glisco/owl-custom-blocks';

const unwatedDefaultBlocks = ["hero", ];

const filterConfig = (k,v) => {
  // console.log("Checking K:" + k + ", V:" + v);
  return !unwatedDefaultBlocks.includes(v.id);
}

const gtDefaultBlocks =  (blocksConfig) => Object.fromEntries(Object.entries(blocksConfig).filter(([k,v]) => filterConfig(k,v)));

export default function applyConfig(config) {
  // Add here your project's configuration here by modifying `config` accordingly

  config.settings = {
    ...config.settings,
    isMultilingual: true,
    supportedLanguages: ['en', 'pt'],
    defaultLanguage: 'en'
  }
  
  config.views = {
    ...config.views,
    contentTypesViews: {
      ...config.views.contentTypesViews,
      'glisco.plone.ecommerce.store' : ReactStoreView,
      'glisco.plone.ecommerce.order' : ReactOrderView,
      'glisco.plone.ecommerce.orderitem' : ReactOrderItemView,
      'glisco.plone.ecommerce.product' : ReactProductView,
      'glisco.plone.ecommerce.poi': ReactPointOfInterestView
    },
  };

  config.settings.appExtras = [
    ...config.settings.appExtras,
    {
      match: '',
      component: GoogleMapsStoreView,
      props: {
        'google-tag': '123456'
      }
    }, 
  ]

  config.blocks.blocksConfig = {
    ...gtDefaultBlocks(config.blocks.blocksConfig), 
    ...customBlocks
  }  

  console.log(config.settings);
  console.log("above is config.settings");
  
  return config;
}
