
import React, { useState } from 'react';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';


import InlineForm from '@plone/volto/components/manage/Form/InlineForm';

import ReviewCarouselView from './ReviewCarouselView';
import ReviewCarouselSchema from './ReviewCarouselSchema';

import imageSVG from '@plone/volto/icons/image.svg';

import {
  Icon,
  SidebarPortal, 
} from '@plone/volto/components';

export const ReviewCarouselMessages = defineMessages({
  showAll: {
    id: 'Show All',
    defaultMessage: 'Show All',
  },
});

const ReviewCarouselEdit = (props) => {
  const { selected, onChangeBlock, block, data, intl, openObjectBrowser, required, resetSubmitUrl } = props;
  const editProps = {
    ...props,
    data : {
      ...data, 
      edit: true
    }
  };
  // const [activeFilter, setActiveFilter] = useState(0);
  
  return (
    <div>
      <SidebarPortal selected={selected}>
        <InlineForm
            icon={<Icon size="24px" name={imageSVG} />}
            schema={ReviewCarouselSchema}
            title={ReviewCarouselSchema.title}
            headerActions={<button onClick={() => {}}>Action</button>}
            footer={<div></div>}
            onChangeField={(id, value) => {
              onChangeBlock(block, {
                ...data,
                [id]: value,
              });
            }}
            formData={data}
          />
          
       </SidebarPortal> 

       <ReviewCarouselView {...editProps} /> 
    </div>
  );
};

export default ReviewCarouselEdit;