// import React, { useEffect, useRef, useState }  from 'react';
import React, { Component }  from 'react';
import { searchContent } from '@plone/volto/actions';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import './content-cards.less';


class ContentCardsView extends Component {


  constructor(props) {
    super(props);
    this.blockClass = "contentCardsContainer"
    this.uid = uuidv4();

  }

  componentWillUnmount() {
  }

  componentDidMount() {

  }

  componentDidMount = () => {
    if (this.props.context!==null && this.props.context!==undefined && this.props.context.length>0) {
      this.props.searchContent(this.props.context[0]["@id"], 
                              {
                                portal_type: "Document",
                                fullobjects: "true"
                              }, 
                              this.uid);
    }    
  }

  componentDidUpdate(prevProps, prevState, snapshot) {

    // if (prevProps.data.image !== this.props.data.image) {
    //   if (this.props.data.image!==null && this.props.data.image[0]!==undefined) {
    //     try{
    //       this.props.getContent(this.props.data.image[0]["@id"], null, this.props.data.image[0].UID, null, true);
    //     } catch (ex) {
    //       console.log(ex);
    //     }
        
    //   }
    // }
    // console.log(prevProps);
    // console.log(this.props);
  }

  cardsData = () => {
    const applicableCards = this.props.cards[this.uid];
    const context = this.props.currentPage;
    return (applicableCards === null || applicableCards === undefined) ? [] : applicableCards.items.filter(i=>i.UID!==context);
  }

  showCard = (card) => {
    const publication = new Date(card.effective);
    console.log(`card of ${publication.getFullYear()}`);
    const cardImg = () => {
      return card.preview_image!==null 
        ? <img src={card.preview_image.scales.preview.download}></img>
        : <></>
    }
    return (
      <div className='card' key={uuidv4()}>
        <a href={card["@id"]}>
          <div className='imageContainer'>
            {cardImg()}
          </div>
          
          <h3>{card.title}</h3>
          <p className="description">{card.description}</p>
          <p className="date">{publication.getFullYear()}</p>
        </a>
      </div>

    )
  }

  cardsContainer = () => {
    return (
      <div className="contentCards">
        {this.cardsData().map(c => this.showCard(c))}
      </div>
    )
  }



  render() {
    return <div className={this.blockClass}>
        {/* {this.cardsContainer()} */}
        {this.cardsData().map(c => this.showCard(c))}
    </div>;
  }
}

const mapStateToProps = (state, ownProps) => {
 
  const propsStruct = {
    ...ownProps.data,
    cards: {
      ...state.search.subrequests
    },
    currentPage: state.content.data.UID
  }
  // console.log(">>>>>>>>>>>> mapStateToProps");
  // console.log(JSON.stringify(propsStruct,null, 2));

  return propsStruct;
}

const mapDispatchToProps = {
  searchContent : searchContent,
}

export default
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ContentCardsView);

