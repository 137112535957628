/** Image Gallery actions.
* @module actions/content_types/send_email
*/

import { SEND_EMAIL, RESET_EMAIL_COMMS } from '../constants/ActionTypes';

/**
* Get FAQ items.
* @function getPages
* @returns {Object} Images action.
*/
export function sendEmail(name, email, message) {
  let xapi = window?.env?.RAZZLE_XAPI || '/xapi/v1';

  return {
    type: SEND_EMAIL,
    request: {
      op: 'post',
      path: `${xapi}/contact-form`,
      data: { 
          sender: name, 
          email: email,
          message: message
        }
    },
 };
}

export function resetEmailComms() {
    return {
        type: RESET_EMAIL_COMMS,
        request: null
    }
}

