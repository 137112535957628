// import React, { useEffect, useRef, useState }  from 'react';
import React, { Component }  from 'react';
import ReactDOM from 'react-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

// var Carousel = require('react-responsive-carousel').Carousel;

import './review-carousel.less';
import {getReviews} from '../../../../actions';
import { connect } from 'react-redux';

class ReviewCarouselView extends Component {


  constructor(props) {
    super(props);

    // select all pages
    this.editMode = props.data.edit ? true : false;
    const selector = this.props.selector != null && this.props.selector.trim().length > 0 ? this.props.selector : null;
    
    this.props.getReviews(selector);

  }

  // componentWillUnmount() {
  //   try {
  //     $(".ui.pointing.secondary a.item").removeClass("darkmode");
  //   } catch(e) {

  //   }  
  // }

  componentDidMount() {
    
  }

  componentDidMount = () => {
    
  }

  componentDidUpdate(prevProps, prevState, snapshot) {

    // console.log(prevProps);
    // console.log(this.props);
  }



  render() {

    // const size = (this.props.galeryImages !== undefined ) ? this.props.galeryImages.length : 0;
    const title = this.props.title != null && this.props.title.trim().length > 0 ? this.props.title : "Customer Reviews";

    const reviewTags = () => {
      try {
        return this.props.reviews.map((review) => {
          return (
            <div className="reviewContainer" key={review.UID}>
             <blockquote>{review.description}</blockquote>
            </div>  
          )
        })
        // return <h1>REVIEWS GO HERE</h1>
      } catch {
        return (
          <div></div>
        )
      }
    }
    

    return (
      <div className="reviewCarouselBlock section" >
        <h2>{title}</h2>  
        <Carousel>
          {reviewTags()}
        </Carousel>  
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {

  // const { error, loaded, loading, images } = state.imageGallery || {error: "", loaded: false, loading: false, images: []};
  // const imageData = images.map(image => {
  //     let rObj = {}
  //     rObj["title"] = image.image.title;
  //     rObj["src"] = image.image.download;
  //     rObj["width"] = image.image.width;
  //     rObj["height"] = image.image.height;
  //     rObj["direction"] = "column";
  //     return rObj;
  // });

  console.log(">>>>>>>>>>>>>>>> REVIEWS MAP STATE TO PROPS");
  console.log(state.reviews);

  const propsStruct = {
    ...ownProps.data,
    // subrequests: {
    //   ...state.content.subrequests
    // },
    reviews: state.reviews.reviews
  }

//   console.log(JSON.stringify(state,null, 2));

  return propsStruct;
}

const mapDispatchToProps = {
  getReviews : getReviews,
}

export default
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ReviewCarouselView);

