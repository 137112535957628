/**
 * Gallery Images reducer.
 * @module reducers/content_types/pages
 */

 import { map } from 'lodash';

 import { GET_POIS } from '../constants/ActionTypes';

 const initialState = {
   error: null,
   loaded: false,
   loading: true,
   pois: []
 };

 /**
  * Faq reducer.
  * @function pois
  * @param {Object} state Current state.
  * @param {Object} action Action to be handled.
  * @returns {Object} New state.
  */
 export default function pois(state = initialState, action = {}) {
   switch (action.type) {
     case `${GET_POIS}_PENDING`:
       return {
         ...state,
         error: "LOADING POIS",
         loading: true,
         loaded: false,
         pois: [],
       };
     case `${GET_POIS}_SUCCESS`:
        
        const results = action.result.items.map((item) => {
            return {
                uid: item.UID,
                id: item.id,
                reference: item.poiReference,
                url: item["@id"],
                title: item.title,
                description: item.description,
                image: item.image==null ? null : item.image.download,
                area: item.area,
                latitudes: item.latitudes.split(",").map(e => parseFloat(e)),
                longitudes: item.longitudes.split(",").map(e => parseFloat(e)),
                products: item.products.map(prod => prod["@id"]),
                created : item.created,
                modified: item.modified,
                store: item.parent["@id"]
                
            }
        });
        // console.log(JSON.stringify(results, null, 2));
        return {
            ...state,
            error: "DATA RECEIVED SUCCESSFULLY",
            loaded: true,
            loading: false,
            pois: results
        };

     case `${GET_POIS}_FAIL`:
       return {
         ...state,
         error: action.error,
         loading: false,
         loaded: false,
         pois: [],
       };
     default:
       return state;
   }
 }
