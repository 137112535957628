import React, { Component }  from 'react';
import { DefaultView } from "@plone/volto/components";

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import jwtDecode from 'jwt-decode';
import { getUser } from '@plone/volto/actions';

import 'react-tabs/style/react-tabs.css';
import 'semantic-ui-less/semantic.less';


// import './picture-gallery.less';

class ReactProductView extends Component {

//   state = { 
//     modalIsOpen: false,
//     currentImage: 0 
//   }


  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    // getImagesFromPlone: PropTypes.func.isRequired,
    userId: PropTypes.string.isRequired,
    getUser: PropTypes.func.isRequired,
  };

  /**
   * Constructor
   * @method constructor
   * @param {Object} props Component properties
   * @constructs Navigation
   */
   constructor(props) {
    super(props);
    this.ref = React.createRef();

  }
  

  componentDidMount = () => {
  }

  componentWillUnmount = () => {
  }


  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
   render() {

    const storeUrl =  "/" + this.props.content.parent["@id"].split("/").slice(4).join("/");
    // const storeUrl = this.props.content.parent["@id"].split('/').slice(4).join('/');

    return (
      <div className="marginContainer ui container">
        {/* <div className="productInfo">
          <div className="productName">Product: {configuration.productInfo.title}</div>
          <div className="purchasePrice">Purchase Price: {configuration.productInfo.price}</div>
          <div className="purchaseArea">Area: {configuration.area}</div>
          <div className="observationDates">Dates: {configuration.dates.map((d) => {
            return d.split("T")[0]
          }).join(", ")}</div>
        </div> */}

        <DefaultView {...this.props}/>
        <div className="additonalProductInfo">
          <h3>Price: €{this.props.content.price}</h3>
          <h3>Code: {this.props.content.partNumber}</h3>
          <h3>Classifications: {this.props.content.classification.map(c => c.title).join(", ")}</h3>
        </div>
        <div className="backToStore">
          <h3><a href={storeUrl}>&lt; Back to Store</a></h3>
        </div>
      </div>  
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  // console.log(">>>>>>>>>>>>>>>>>>> ORDER VIEW mapStateToProps: ");
  // console.log(JSON.stringify(state.orders, null, 2));
  const shouldRedirect = (user) => {
    try {
      let roles = user.roles.map(r => r.toLowerCase());
      let allowAccess = ["manager", "site administrator", "store manager"].reduce(function (previousValue, currentValue) {
        return previousValue || roles.includes(currentValue);
      }, false);
      return !allowAccess;
    } catch(ex) {
      // This means we don't have user data, particulaly about their roles. 
      // We may or may not have to redirect, but we don't know yet.
      // Returning undefined, as simply true/false is not sufficient yet
      return undefined;
    }
  }

  const userId = state.userSession.token ? jwtDecode(state.userSession.token).sub : undefined;

  const redirect = (userId == undefined) ? true : shouldRedirect(state.users.user);

  const known = (redirect != undefined);

  const propsStruct = {
    ...ownProps.data,
    content : state.content.data
  }

  return propsStruct;
}

const mapDispatchToProps = {
  getUser : getUser,
}

export default
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ReactProductView);
