const IContentCardsSchema = {
    title: 'Hero Banner',

    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: [
          'context',
          'title',
          'description',
          'date'
        ],
      },
    ],

    properties: {

      context: {
        title: 'Context',
        description: 'Choose Parent Page',
        widget: 'object_browser',
        selectableTypes : ['Page'],
        mode: 'link'
      },


      title: {
        title: 'Title',
        description: 'Show Titles',
       type: 'boolean'
      },


      description: {
        title: 'Lede text',
        description: 'Show descriptions',
       type: 'boolean'
      },

      date: {
        title: 'Date',
        description: 'Show dates',
       type: 'boolean'
      }

    },

    required: ['context'],
  };

  export default IContentCardsSchema;
