/** Image Gallery actions.
* @module actions/content_types/get_pages
*/

import { GET_ORDERS } from '../constants/ActionTypes';

/**
* Get FAQ items.
* @function getPages
* @returns {Object} Images action.
*/
export function getOrders() {
  return {
    type: GET_ORDERS,
    request: {
      op: 'get',
      path: `/@search?portal_type=glisco.plone.ecommerce.order&fullobjects=1`,
    },
 };
}

