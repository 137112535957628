/**
 * Gallery Images reducer.
 * @module reducers/content_types/pages
 */

 import { map } from 'lodash';

 import { SEND_EMAIL, RESET_EMAIL_COMMS } from '../constants/ActionTypes';

 const initialState = {
   error: null,
   loaded: false,
   loading: true,
   emailSent: true
 };

 /**
  * Faq reducer.
  * @function pois
  * @param {Object} state Current state.
  * @param {Object} action Action to be handled.
  * @returns {Object} New state.
  */
 export default function email(state = initialState, action = {}) {
   switch (action.type) {
     case `${RESET_EMAIL_COMMS}_SUCCESS`:
        return {
            ...state,
            error: "",
            loading: false,
            loaded: false,
            emailSent: false,
          };
     case `${SEND_EMAIL}_PENDING`:
       return {
         ...state,
         error: "SENDING EMAIL",
         loading: true,
         loaded: false,
         emailSent: false,
       };
     case `${SEND_EMAIL}_SUCCESS`:
        
        return {
            ...state,
            error: "EMAIL SENT SUCCESSFULLY",
            loaded: true,
            loading: false,
            emailSent: true
        };

     case `${SEND_EMAIL}_FAIL`:
       return {
         ...state,
         error: action.error,
         loading: false,
         loaded: false,
         emailSent: false,
       };
     default:
       return state;
   }
 }
