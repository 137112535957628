import React, { Component }  from 'react';
import { DefaultView } from "@plone/volto/components";

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import jwtDecode from 'jwt-decode';
import { getUser } from '@plone/volto/actions';

import 'react-tabs/style/react-tabs.css';
import 'semantic-ui-less/semantic.less';


// import './picture-gallery.less';

class ReactPointOfInterestView extends Component {

//   state = { 
//     modalIsOpen: false,
//     currentImage: 0 
//   }


  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  // static propTypes = {
  //   // getImagesFromPlone: PropTypes.func.isRequired,
  //   userId: PropTypes.string.isRequired,
  //   getUser: PropTypes.func.isRequired,
  // };

  /**
   * Constructor
   * @method constructor
   * @param {Object} props Component properties
   * @constructs Navigation
   */
   constructor(props) {
    super(props);
    this.ref = React.createRef();

  }
  

  componentDidMount = () => {

  }

  componentWillUnmount = () => {
  }

  geoCoordinatesTable = (geoCoordinates) => {
    let index=1;
    return geoCoordinates.map((c) => {
        return <tr>
          <td data-label="Name">Coord {index++}</td>
          <td data-label="Age">{c.lat}</td>
          <td data-label="Job">{c.lng}</td>
        </tr>
    });
  }


  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
   render() {

    const latitudes = this.props.content.latitudes.split(",").map(e => parseFloat(e));
    const longitudes = this.props.content.longitudes.split(",").map(e => parseFloat(e));
    const storeUrl =  "/" + this.props.content.parent["@id"].split("/").slice(4).join("/");

    const geoCoordinates = [];

    // console.log("+++++++++++++ ================== ++++++++++++++");
    // console.log(JSON.stringify(this.props.content, null, 2));
    latitudes.forEach((element, index) => {
      try {
        geoCoordinates.push( {
          lat: element,
          lng: longitudes[index]
        });
      } catch(e) {
        
      }
    } );

    return (
      <div className="marginContainer">
        <h1>Point of Interest {this.props.content.title}</h1>
        {/* <h2 className={orderStatus}>{configuration.location} - {this.props.content.status.title}</h2> */}
        {/* <h2>Part of <a href={this.orderUrl()}>{this.props.content.parent.title}</a></h2> */}

        <h3>Product Information</h3>

        {/* <div className="productInfo">
          <div className="productName">Product: {configuration.productInfo.title}</div>
          <div className="purchasePrice">Purchase Price: {configuration.productInfo.price}</div>
          <div className="purchaseArea">Area: {configuration.area}</div>
          <div className="observationDates">Dates: {configuration.dates.map((d) => {
            return d.split("T")[0]
          }).join(", ")}</div>
        </div> */}

        <div className="coordinates">
          <h3>Coordinates</h3>

          <table className="ui celled table">
            <thead>
              <tr><th>Name</th>
              <th>Lat</th>
              <th>Lng</th>
            </tr></thead>
            <tbody >
              {this.geoCoordinatesTable(geoCoordinates)}
            </tbody>
          </table>
        </div>

        <h3>Technical Data</h3>
        
        {/* <pre>
          {JSON.stringify(configuration, null, 2)}
        </pre> */}
        {/* <DefaultView {...this.props}/> */}

        <div className="backToStore">
          <h3><a href={storeUrl}>&lt; Back to Store</a></h3>
        </div>

      </div>  
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  // console.log(">>>>>>>>>>>>>>>>>>> ORDER VIEW mapStateToProps: ");
  // console.log(JSON.stringify(state.orders, null, 2));
  const shouldRedirect = (user) => {
    try {
      let roles = user.roles.map(r => r.toLowerCase());
      let allowAccess = ["manager", "site administrator", "store manager"].reduce(function (previousValue, currentValue) {
        return previousValue || roles.includes(currentValue);
      }, false);
      return !allowAccess;
    } catch(ex) {
      // This means we don't have user data, particulaly about their roles. 
      // We may or may not have to redirect, but we don't know yet.
      // Returning undefined, as simply true/false is not sufficient yet
      return undefined;
    }
  }

  const userId = state.userSession.token ? jwtDecode(state.userSession.token).sub : undefined;

  const redirect = (userId == undefined) ? true : shouldRedirect(state.users.user);

  const known = (redirect != undefined);

  const propsStruct = {
    ...ownProps.data,
    content : state.content.data
  }

  return propsStruct;
}

const mapDispatchToProps = {
  getUser : getUser,
}

export default
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ReactPointOfInterestView);
