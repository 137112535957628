/**
 * Gallery Images reducer.
 * @module reducers/content_types/pages
 */

 import { map } from 'lodash';

 import { GET_STORES } from '../constants/ActionTypes';

 const initialState = {
   error: null,
   loaded: false,
   loading: true,
   products: []
 };

 /**
  * Faq reducer.
  * @function products
  * @param {Object} state Current state.
  * @param {Object} action Action to be handled.
  * @returns {Object} New state.
  */
 export default function stores(state = initialState, action = {}) {
   switch (action.type) {
     case `${GET_STORES}_PENDING`:
       return {
         ...state,
         error: "LOADING STORES",
         loading: true,
         loaded: false,
         stores: [],
       };
     case `${GET_STORES}_SUCCESS`:
        // console.log(">>>>>>>>>>>>>>>>>>>>>>> STORES REDUCER <<<<<<<<<<<<<<<<<<<<<<<");
        const results = action.result.items.map((item) => {
          // console.log("vvvvvvvvvvvvvvv HERE IS A STORE vvvvvvvvvvvvvv");
          // console.log(item);
            return {
                uid: item.UID,
                url: item["@id"],
                title: item.title,
                description: item.description,
                language: item.language
            }
        });
        // console.log(JSON.stringify(results, null, 2));
        return {
            ...state,
            error: "DATA RECEIVED SUCCESSFULLY",
            loaded: true,
            loading: false,
            stores: results
        };

     case `${GET_STORES}_FAIL`:
       return {
         ...state,
         error: action.error,
         loading: false,
         loaded: false,
         stores: [],
       };
     default:
       return state;
   }
 }
