/**
 * Add your action types here.
 * @module constants/ActionTypes
 * @example
 * export const UPDATE_CONTENT = 'UPDATE_CONTENT';
 */

 export const GET_PRODUCTS = 'GET_PRODUCTS';
 export const GET_CUSTOMERS = 'GET_CUSTOMERS';
 export const GET_ORDERS = 'GET_ORDERS';
 export const GET_ORDER_ITEMS = 'GET_ORDER_ITEMS';
 export const GET_POIS = 'GET_POIS';
 export const GET_STORES = 'GET_STORES';
 export const GET_IMAGES = 'GET_IMAGES';
 export const GET_REVIEWS = 'GET_REVIEWS';
 export const SEND_EMAIL = 'SEND_EMAIL';
 export const RESET_EMAIL_COMMS = 'RESET_EMAIL_COMMS';