/**
 * Header component.
 * @module components/theme/Header/Header
 */

 import React, { Component } from 'react';
 import { Container, Segment } from 'semantic-ui-react';
 import PropTypes from 'prop-types';
 import { connect } from 'react-redux';
 import {FiShoppingCart, FiUser} from "react-icons/fi";
 import Cookies from 'universal-cookie';

 
 import {
   Anontools,
   LanguageSelector,
   Logo,
   Navigation,
   SearchWidget,
 } from '@plone/volto/components';
 
 /**
  * Header component class.
  * @class Header
  * @extends Component
  */
 class Header extends Component {
   /**
    * Property types.
    * @property {Object} propTypes Property types.
    * @static
    */
   static propTypes = {
     token: PropTypes.string,
     pathname: PropTypes.string.isRequired,
   };
 
   /**
    * Default properties.
    * @property {Object} defaultProps Default properties.
    * @static
    */
   static defaultProps = {
     token: null,
   };
 
   cartItems = () => {
       try {
        let cookies = new Cookies();
        let items = cookies.get("cart");
        return items.length >0 ? items : 0;
       } catch (e) {
        return "0";
       }
     
   }
 
   userName = () => {
    let cookies = new Cookies();
    let user = cookies.get("username");
    return (user !== undefined) ? user : "Guest";   
   }
  
   /**
    * Render method.
    * @method render
    * @returns {string} Markup for the component.
    */
   render() {
     return (
       <Segment basic className="header-wrapper" role="banner">
         <Container>
           <div className="header">
             <div className="logo-nav-wrapper">
               <div className="logo">
                 <Logo />
               </div>
               <Navigation pathname={this.props.pathname} />
             </div>
             
             <div className="tools-search-wrapper">
                <div className="cart-wrapper">
                  <div id="userTools">
                    <div id="cart">
                      <a href="/cart">
                        <FiShoppingCart size={25} className="icon"/>
                        <span id="cartItems">{this.cartItems()}</span>
                      </a>
                    </div>
                    <div id="profile">
                      <a className="profile-link" href="/profile">
                        <FiUser size={25} className="icon"/>
                        {this.userName()}
                      </a>
                    </div>
                  </div>  
                </div>  
               <LanguageSelector />
               {/* {!this.props.token && (
                 <div className="tools">
                   <Anontools />
                 </div>
               )}
               <div className="search">
                 <SearchWidget />
               </div> */}
             </div>
           </div>
         </Container>
       </Segment>
     );
   }
 }
 
 export default connect((state) => ({
   token: state.userSession.token,
 }))(Header);
 