import React, { Component, useState }  from 'react';
import {MDBInput} from "mdbreact";

import './contact-form.less';
import {sendEmail, resetEmailComms} from '../../../../actions';
import { connect } from 'react-redux';

const EMAIL_FIELD = 'userEmail';
const NAME_FIELD = 'userName';
const MESSAGE_FIELD = 'userMessage';
const SUBMIT_FIELD = 'submit';

class ContactFormView extends Component {


  constructor(props) {
    super(props);

    this.state = {
        userName: '',
        userEmail: '',
        userMessage: '',
        displayThankYou: false
    }
    
  }

  componentWillUnmount = () => {
      this.state = null;
  }

  componentDidMount = () => {
    this.props.resetEmailComms();
  }

  componentDidUpdate = (prevProps) => {

    console.log("componentDidUpdate **********");
    console.log(this.props.email);
    console.log("previous:");
    console.log(prevProps);

    if (this.props.email.emailSent && !prevProps.email.emailSent) {
        this.props.resetEmailComms();
        this.setState({
            userName: '',
            userEmail: '',
            userMessage: '',
            displayThankYou: true
        });
    }
}

  isFormEmpty = () => {
      return ((this.state.userName==null || this.state.userName.length == 0) &&
              (this.state.userEmail==null || this.state.userEmail.length == 0) &&
              (this.state.userMessage==null || this.state.userMessage.length == 0)
             )
  }

  isValid = (field) => {
    if (field===NAME_FIELD) {
        return (this.state.userName!==null && this.state.userName.length > 0) ;
    } else if (field===EMAIL_FIELD) {
        const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        return (this.state.userEmail!==null && this.state.userEmail.match(mailformat) );
    } else if (field===MESSAGE_FIELD) {
        return (this.state.userMessage!==null && this.state.userMessage.length > 0 );
    } else if (field===SUBMIT_FIELD) {
        return (this.isValid(NAME_FIELD) && this.isValid(EMAIL_FIELD) && this.isValid(MESSAGE_FIELD));
    }
  }

  validate = (evt) => {
    //   console.log("in Validation field");
    //   console.log(evt.target.id);
      const fieldValidation = `${evt.target.id}Validation`;
      this.setState({
        fieldValidation: this.isValid(evt.target.id)
      })
  }

  validationClass = (field) => {

    if (this.isFormEmpty()) {
        // Basically, on an empty form, disable the submit button but do not 
        // complain about empty fields, otherwiser form starts off with a 
        // bunch or error messages even before the user interacts with it
        return (field === SUBMIT_FIELD) ? 'validation invalid' : 'validation valid'
    } else {
        return (this.isValid(field)) ? 'validation valid' : 'validation invalid';
    }
    
  }

  submitHandler = (evt) => {
      evt.preventDefault();
      if (this.isValid('submit')) {
        this.props.sendEmail(this.state.userName, this.state.userEmail, this.state.userMessage);
      }
  }

  render() {

    // const size = (this.props.galeryImages !== undefined ) ? this.props.galeryImages.length : 0;
    

    return (
      <div className="contactFormlBlock section" >
        <h2>Contact</h2>  
        {(!this.state.displayThankYou) && 
            <form onSubmit={this.submitHandler} noValidate>
                <MDBInput 
                        id={NAME_FIELD}
                        label='name' 
                        group
                        type="text"
                        validate
                        error="wrong"
                        success="right"
                        size="sm"
                        onBlur={this.validate}
                        onChange={e => this.setState({userName: e.target.value})} />
                <div className={this.validationClass(NAME_FIELD)} id="nameValidation">Invalid Name</div>

                <MDBInput 
                        label='email' 
                        group
                        type="email"
                        validate
                        error="wrong"
                        success="right"
                        size="sm"
                        id={EMAIL_FIELD}
                        onBlur={this.validate}
                        onChange={e => this.setState({userEmail: e.target.value})} />
                <div className={this.validationClass(EMAIL_FIELD)} id="emailValidation">Invalid Email Address</div>
                
                <MDBInput 
                        id={MESSAGE_FIELD}
                        label='message' 
                        group
                        type="textarea"
                        validate
                        error="wrong"
                        success="right"
                        size="sm"
                        onBlur={this.validate}
                        onChange={e => this.setState({userMessage: e.target.value})} />        
                <div className={this.validationClass(MESSAGE_FIELD)} id="messageValidation"></div>

                <div className='buttonsContainer'>
                    <button label="send" className={this.validationClass(SUBMIT_FIELD)} onClick={(e) => this.submitHandler(e)}>Send</button>
                </div>
                
            </form>
        }

        {(this.state.displayThankYou) && 
            <div className='contactUsThankYou'>
                <h3>Thank you</h3>
                <p className='thankYouText'>Thank you for your message. We will get in touch shortly.</p>
                <p className='signature'>The OWL Team</p>
            </div>    
        }
        
        
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {

  console.log(">>>>>>>>>>>>>>>>>>>>>>>>>> Contact Form View mapStateToProps");
  console.log(state);

  const propsStruct = {
    ...ownProps.data,
    email: state.email
  }

  return propsStruct;
}

const mapDispatchToProps = {
    sendEmail: sendEmail,
    resetEmailComms: resetEmailComms
}

export default
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ContactFormView);

