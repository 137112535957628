/**
 * Gallery Images reducer.
 * @module reducers/content_types/pages
 */

 import { map } from 'lodash';

 import { GET_IMAGES } from '../constants/ActionTypes';

 const initialState = {
   error: null,
   loaded: false,
   loading: true,
   products: []
 };

 /**
  * Faq reducer.
  * @function products
  * @param {Object} state Current state.
  * @param {Object} action Action to be handled.
  * @returns {Object} New state.
  */
 export default function images(state = initialState, action = {}) {
   switch (action.type) {
     case `${GET_IMAGES}_PENDING`:
       return {
         ...state,
         error: "LOADING IMAGES",
         loading: true,
         loaded: false,
         products: [],
       };
     case `${GET_IMAGES}_SUCCESS`:
        // console.log(">>>>>>>>>>>>>>>>>>>>>>> IMAGES REDUCER <<<<<<<<<<<<<<<<<<<<<<<");
        const results = action.result.items;
        // console.log(JSON.stringify(results, null, 2));
        return {
            ...state,
            error: "DATA RECEIVED SUCCESSFULLY",
            loaded: true,
            loading: false,
            images: results
        };

     case `${GET_IMAGES}_FAIL`:
       return {
         ...state,
         error: action.error,
         loading: false,
         loaded: false,
         products: [],
       };
     default:
       return state;
   }
 }
