const IImageCarouselSchema = {
    title: 'Image Carousel',

    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: [
          'selector'
        ],
      },
    ],

    properties: {
      selector: {
        title: 'selector',
        description: 'Insert Image Seclector',
        widget: 'text',
      },

    },

    required: [],
  };

  export default IImageCarouselSchema;
