import React, { Component }  from 'react';
import { DefaultView } from "@plone/volto/components";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Card, Icon, Image } from 'semantic-ui-react'


import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import jwtDecode from 'jwt-decode';
import { getUser } from '@plone/volto/actions';
import { getProducts, getOrderItems, getStores } from 'actions';

import { getBaseUrl } from '@plone/volto/helpers';
import config from '@plone/volto/registry';

import 'react-tabs/style/react-tabs.css';
import 'semantic-ui-less/semantic.less';


// import './picture-gallery.less';

class ReactOrderView extends Component {

//   state = { 
//     modalIsOpen: false,
//     currentImage: 0 
//   }


  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  // static propTypes = {
  //   // getImagesFromPlone: PropTypes.func.isRequired,
  //   userId: PropTypes.string.isRequired,
  //   getUser: PropTypes.func.isRequired,
  // };

  /**
   * Constructor
   * @method constructor
   * @param {Object} props Component properties
   * @constructs Navigation
   */
   constructor(props) {
    super(props);

    this.blockClass = "ReactOrderView";
    this.ref = React.createRef();

  }
  

  componentDidMount = () => {
    // window.addEventListener('scroll', this.handleScroll);
    // console.log("+++++++++++++++++++++++++++++++++++++++++");
    // console.log("+++++++++++++++++++++++++++++++++++++++++");
    // console.log("ReactOrderView did mount");
    // this.props.getUser(this.props.userId);
    // this.props.getProducts();
    const searchScope=`/${this.props.site}/Members/${this.props.client}/${this.props.order.id}`; 
    const searchDepth = 2;
    // console.log(">>>>>>> Getting Order ITEMS from " + searchScope + " using depth = " + searchDepth);
    this.props.getOrderItems(searchScope, searchDepth);
    this.props.getStores('/', 4);
    // console.log("+++++++++++++++++++++++++++++++++++++++++");
    // console.log("+++++++++++++++++++++++++++++++++++++++++");
  }

  componentWillUnmount = () => {
  }

  orderItemCards = () => {
    return Array.isArray(this.props.orderItems) ? this.props.orderItems.map((o) => {
      const orderStatus = (o.status!=null) ? o.status.toLowerCase().replace(" ", "-") : "new";
      const cardMetaClass = 'status ' + orderStatus;
      const orderColours = {
        "new" : "blue",
        "in-progress": "orange",
        "cancelled" : "red",
        "fullfilled": "green"
      };
      const created = new Date(o.created);
      const day = (created.getDate() < 10 ? '0' : '') + created.getDate();
      const month = created.toLocaleString('default', { month: 'short' });
      const orderItemUrl = "/Members/" + o.client + "/" + o.order + "/" + o.orderItemId;

      return <Card key={o.orderItemId} href={orderItemUrl} color={orderColours[orderStatus]} >
              <Image src={o.image} wrapped ui={false} />
              <Card.Content data-status={orderStatus}>
                <Card.Header>{o.location}</Card.Header>
                <Card.Meta>
                  <span className={cardMetaClass}>{o.status} {o.title}</span>
                </Card.Meta>
                <Card.Description>
                {o.description}
                </Card.Description>
              </Card.Content>
              <Card.Content extra>
                  <Icon name='users' /><span className="client">{o.client} </span>&nbsp;
                  <span className="date">{day}/{month}/{created.getFullYear()}</span>
              </Card.Content>
            </Card>
    }) : []
  }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
   render() {

    // const storeUrl =  "/" + this.props.order.parent["@id"].split("/").slice(4).join("/");
    const storeUrl =  (this.props.stores!== undefined && this.props.stores.length > 0) ? `/${this.props.stores[0].url.split('/').slice(4).join('/')}`: '';
    const orderStatus = "order-status " + this.props.order.status.token;

    return (
      <div className="marginContainer ui container">
        <h1>ORDER {this.props.order.orderId}</h1>
        <h2 className={orderStatus}>{this.props.order.status.title} Order</h2>
        {this.orderItemCards()}
        <div className="backToStore">
          <h3><a href={storeUrl}>&lt; Back to Store</a></h3>
        </div>
      </div>  

    );
  }
}

const mapStateToProps = (state, ownProps) => {
  // console.log(">>>>>>>>>>>>>>>>>>> ORDER VIEW mapStateToProps: ");
  // console.log(JSON.stringify(state, null, 2));
  const shouldRedirect = (user) => {
    try {
      let roles = user.roles.map(r => r.toLowerCase());
      let allowAccess = ["manager", "site administrator", "store manager"].reduce(function (previousValue, currentValue) {
        return previousValue || roles.includes(currentValue);
      }, false);
      return !allowAccess;
    } catch(ex) {
      // This means we don't have user data, particulaly about their roles. 
      // We may or may not have to redirect, but we don't know yet.
      // Returning undefined, as simply true/false is not sufficient yet
      return undefined;
    }
  }

  const userId = state.userSession.token ? jwtDecode(state.userSession.token).sub : undefined;

  const redirect = (userId == undefined) ? true : shouldRedirect(state.users.user);

  const known = (redirect != undefined);

  // console.log(">>>>>>>>>>>>>>>>>>> ORDER VIEW @id: " + state.content.data["@id"]);

  const client = state.content.data["@id"].split("Members")[1].split("/")[1];
  const siteName = state.content.data["@id"].includes("localhost") 
                      ? state.content.data["@id"].split("/").slice(3, 4)[0]
                      : '';
  
  const propsStruct = {
    ...ownProps.data,
    site: siteName,
    client : client,
    user: state.users.user,
    userId: userId,
    redirect: redirect,
    known: known ? "KNOWN" : "NOT KNOWN",
    order: state.content.data,
    orderItems: state.order_items.orderItems,
    stores: state.stores.stores
  }

  // console.log(">>>>>>>>>>>>>>>>>>> ORDER VIEW propsStruct: ");

  // console.log(propsStruct);

  return propsStruct;
}

const mapDispatchToProps = {
  getUser : getUser,
  getProducts : getProducts,
  getOrderItems : getOrderItems,
  getStores: getStores
}

export default
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ReactOrderView);
