import heroSVG from '@plone/volto/icons/hero.svg';
import appsSVG from '@plone/volto/icons/apps.svg';
import emailSVG from '@plone/volto/icons/email.svg';
import heartSVG from '@plone/volto/icons/heart.svg';

import HeroBannerView from './HeroBanner/HeroBannerView';
import HeroBannerEdit from './HeroBanner/HeroBannerEdit';

import ContentCardsView from './ContentCards/ContentCardsView';
import ContentCardsEdit from './ContentCards/ContentCardsEdit';

import ImageCarouselView from './ImageCarousel/ImageCarouselView';
import ImageCarouselEdit from './ImageCarousel/ImageCarouselEdit';

import ReviewCarouselView from './ReviewCarousel/ReviewCarouselView';
import ReviewCarouselEdit from './ReviewCarousel/ReviewCarouselEdit';

import ContactFormView from './ContactForm/ContactFormView';
import ContactFormEdit from './ContactForm/ContactFormEdit';

// import { ObjectBrowserWidgetMode } from '@plone/volto/components/manage/Widgets/ObjectBrowserWidget';

export { HeroBannerView, HeroBannerEdit};

export const customBlocks = {
    // Hero Banner
    "heroBanner" : {
      id: 'heroBanner',
      title: 'Hero Banner',
      icon: heroSVG,
      group: 'common',
      view: HeroBannerView,
      edit: HeroBannerEdit,
      restricted: false,
      mostUsed: false,
      sidebarTab: 1,
      security: {
        addPermission: [],
        view: [],
      }
    },

    "contentCards" : {
      id: 'contentCards',
      title: 'Content Cards',
      icon: appsSVG,
      group: 'common',
      view: ContentCardsView,
      edit: ContentCardsEdit,
      restricted: false,
      mostUsed: false,
      sidebarTab: 1,
      security: {
        addPermission: [],
        view: [],
      }
    }, 

    "imageCarousel" : {
      id: 'imageCarousel',
      title: 'Image Carousel',
      icon: appsSVG,
      group: 'common',
      view: ImageCarouselView,
      edit: ImageCarouselEdit,
      restricted: false,
      mostUsed: false,
      sidebarTab: 1,
      security: {
        addPermission: [],
        view: [],
      }
    },

    "reviewCarousel" : {
      id: 'reviewCarousel',
      title: 'Review Carousel',
      icon: heartSVG,
      group: 'common',
      view: ReviewCarouselView,
      edit: ReviewCarouselEdit,
      restricted: false,
      mostUsed: false,
      sidebarTab: 1,
      security: {
        addPermission: [],
        view: [],
      }
    },

    "contactForm" : {
      id: 'contactForm',
      title: 'Contact Form',
      icon: emailSVG,
      group: 'common',
      view: ContactFormView,
      edit: ContactFormEdit,
      restricted: false,
      mostUsed: false,
      sidebarTab: 1,
      security: {
        addPermission: [],
        view: [],
      }
    }
};

export default function install(config) {

  Object.entries(customBlocks).forEach(([key, value]) => {
    config.blocks.blocksConfig[key]=value;
  });

//   if (!config.widgets.widget.pick_object)
//     config.widgets.widget.pick_object = ObjectBrowserWidgetMode('link');

  return config;
};
