// import React, { useEffect, useRef, useState }  from 'react';
import React, { Component }  from 'react';
import ReactDOM from 'react-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

// var Carousel = require('react-responsive-carousel').Carousel;

import './image-carousel.less';
import $ from 'jquery';
import { getContent } from '@plone/volto/actions';
import {getImages} from '../../../../actions';
import { connect } from 'react-redux';

class ImageCarouselView extends Component {


  constructor(props) {
    super(props);

    // select all pages
    this.editMode = props.data.edit ? true : false;
    const selector = this.props.selector != null && this.props.selector.trim().length > 0 ? this.props.selector : "gallery";

    this.props.getImages(selector);

  }

  // componentWillUnmount() {
  //   try {
  //     $(".ui.pointing.secondary a.item").removeClass("darkmode");
  //   } catch(e) {

  //   }  
  // }

  componentDidMount() {
    
  }

  componentDidMount = () => {
    
  }

  componentDidUpdate(prevProps, prevState, snapshot) {

    // console.log(prevProps);
    // console.log(this.props);
  }



  render() {

    const size = (this.props.galeryImages !== undefined ) ? this.props.galeryImages.length : 0;

    const imageTags = () => {
      try {
        return this.props.galeryImages.map((image) => {
          return (
            <div className="galleryImage" key={image.UID}>
              <img src={`${image.image.download}`}/>
            </div>  
          )
        })
      } catch {
        return (
          <div></div>
        )
      }
    }
    

    return (
      <div className="imageCarouselBlock section" >
        <Carousel>
          {imageTags()}
        </Carousel>  
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {

  // const { error, loaded, loading, images } = state.imageGallery || {error: "", loaded: false, loading: false, images: []};
  // const imageData = images.map(image => {
  //     let rObj = {}
  //     rObj["title"] = image.image.title;
  //     rObj["src"] = image.image.download;
  //     rObj["width"] = image.image.width;
  //     rObj["height"] = image.image.height;
  //     rObj["direction"] = "column";
  //     return rObj;
  // });

  // console.log(">>>>>>>>>>>>>>>> MAP STATE TO PROPS");
  // console.log(state);

  const propsStruct = {
    ...ownProps.data,
    subrequests: {
      ...state.content.subrequests
    },
    galeryImages: state.images.images
  }

  // console.log(JSON.stringify(propsStruct,null, 2));

  return propsStruct;
}

const mapDispatchToProps = {
  getImages : getImages,
}

export default
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ImageCarouselView);

