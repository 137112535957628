import React, { Component }  from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import jwtDecode from 'jwt-decode';

// import './picture-gallery.less';

class GoogleMapsStoreView extends Component {


  /**
   * Constructor
   * @method constructor
   * @param {Object} props Component properties
   * @constructs Navigation
   */
   constructor(props) {
    super(props);

    this.blockClass = "GoogleMapsStoreView";
    this.ref = React.createRef();

  }
  

  componentDidMount = () => {
  }

  componentWillUnmount = () => {
  }


  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
   render() {

    return (
      <div className="googleMapsStoreView">
        <h1>MY GoogleMapsStoreView GOES HERE</h1>
      </div>  
    );
  }
}

const mapStateToProps = (state, ownProps) => {

  const propsStruct = {
    ...ownProps.data
  }

  return propsStruct;
}

const mapDispatchToProps = {
}

export default
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(GoogleMapsStoreView);
