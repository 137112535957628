/**
 * Footer component.
 * @module components/theme/Footer/Footer
 */

import React from 'react';
import { Container, List, Segment } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { FormattedMessage, useIntl, injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import config from '@plone/volto/registry';


/**
 * Component to display the footer.
 * @function Footer
 * @param {Object} intl Intl object
 * @returns {string} Markup of the component
 */
const Footer = ({ intl }) => {
  const { settings } = config;
  const lang = useSelector((state) => state.intl.locale);

  const messages = {
    en: {
      "TandCs": "Terms & Conditions",
      "TandCsURL": "/en/about-us/termsandconditions",
      "Privacy": "Privacy Policy",
      "PrivacyURL": "/en/about-us/privacy-policy",
      "Contact" : "Contact us",
      "ContactURL" : "/en/about-us/contact-us",
      "UserManualURL": "/en/about-us/user-manual",
      "UserManual": "User Manual"
    },
    pt: {
      "TandCs": "Termos & Condições",
      "TandCsURL": "/pt/sobre-nos/termos-condicoes",
      "Privacy": "Política de Privacidade",
      "PrivacyURL": "/pt/sobre-nos/politica-privacidade",
      "Contact" : "Contacte-nos",
      "ContactURL" : "/pt/sobre-nos/contacto",
      "UserManualURL": "/pt/sobre-nos/manual-utilizador",
      "UserManual": "Manual Utilizador"
    },
  };

  const getMessage = (lang, msgId) => {
    try {
      return messages[lang][msgId];
    } catch (ex) {
      return "";
    }
  }

  
  return (
    <Segment
      role="contentinfo"
      vertical
      padded
      inverted
      color = "grey"
      textAlign="center"
      id="footer"
    >
      
      <Container>
       <div className="contentlinks">
          <List horizontal inverted>
            <Link className="item" to={getMessage(lang, 'TandCsURL')}>         
              {getMessage(lang, 'TandCs')}
            </Link>
            <Link className="item" to={getMessage(lang, 'PrivacyURL')}>         
              {getMessage(lang, 'Privacy')}
            </Link>
            <Link className="item" to={getMessage(lang, 'ContactURL')}>      
               {getMessage(lang, 'Contact')}
            </Link>
            <Link className="item" to={getMessage(lang, 'UserManualURL')}>      
               {getMessage(lang, 'UserManual')}
            </Link>
          </List>
        </div>
        <div>lang is ({lang})</div>
        <div className="sociallinks">
         <script src="https://kit.fontawesome.com/9d87bc0db8.js" crossOrigin="anonymous"></script>
          <List horizontal inverted>
            {/* <a className="item" href="https://facebook.com/ourwatchleads" target="new">         
              <i className="fab fa-facebook-f"></i>
            </a>
            <a className="item" href="https://instagram.com/owl_ourwatchleads" target="new">         
              <i className="fab fa-instagram"></i>
            </a> */}

            <a className="item" href="https://facebook.com/ourwatchleads" target="new"><i className="fab fa-facebook-f" aria-hidden="true"></i></a>
            <a className="item" href="https://instagram.com/owl_ourwatchleads" target="new"><i className="fab fa-instagram" aria-hidden="true"></i></a>
            <a className="item" href="https://www.linkedin.com/company/owl-our-watch-leads" target="new"><i className="fab fa-linkedin-in" aria-hidden="true"></i></a>

            {/* <Link className="item" to="https://www.linkedin.com/company/owl-our-watch-leads" target="new">         
              <i className="fab fa-linkedin-in"></i>
            </Link> */}
          </List>
        </div>
      </Container>
    </Segment>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
Footer.propTypes = {
  /**
   * i18n object
   */
};

export default injectIntl(Footer);
