/** Image Gallery actions.
* @module actions/content_types/get_pages
*/

import { GET_STORES } from '../constants/ActionTypes';

/**
* Get FAQ items.
* @function getPages
* @returns {Object} Images action.
*/
export function getStores() {
  return {
    type: GET_STORES,
    request: {
      op: 'get',
      path: `/@search?portal_type=glisco.plone.ecommerce.store&fullobjects=1`,
    },
 };
}

