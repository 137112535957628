const IReviewCarouselSchema = {
    title: 'Review Carousel',

    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: [
          'title',
          'selector'
        ],
      },
    ],

    properties: {
        title: {
            title: 'title',
            description: 'Review Carousel Title',
            widget: 'text',
          },
      
      selector: {
        title: 'selector',
        description: 'Insert Review Seclector',
        widget: 'text',
      },

    },

    required: [],
  };

  export default IReviewCarouselSchema;
