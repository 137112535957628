// import React, { useEffect, useRef, useState }  from 'react';
import React, { Component }  from 'react';
import './hero-banner.less';
import $ from 'jquery';
import { getContent } from '@plone/volto/actions';
import { connect } from 'react-redux';

class HeroBannerView extends Component {


  constructor(props) {
    super(props);

    // select all pages
    this.editMode = props.data.edit ? true : false;
    this.fixedToTop = props.data.fixToTop && !this.editMode ? "fixToTop" : "";
    this.showCursor = props.data.showCursor ? "showCursor" : "";
    this.textBackground = props.data.textBackground;
    this.heroHeight = props.data.height || 'fullHeight';
    this.blockClass = "heroBannerBlock hero section " + this.fixedToTop + " " + this.showCursor + " " + this.textBackground + " " + this.heroHeight;  
    this.heading = props.data.heading;
    this.subheading = props.data.subheading;
    this.highlight = props.data.highlight;
    this.lede = props.data.lede;

  }

  componentWillUnmount() {
    try {
      $(".ui.pointing.secondary a.item").removeClass("darkmode");
    } catch(e) {

    }  
  }

  componentDidMount() {
    const heights = {
      "halfHeight" : "50vh",
      "twoThirdsHeight" : "75vh",
      "fullHeight" : "100vh"
    }

    try {
      if (typeof window !== undefined) {
        // const heroHeightVH = props.data.halfHeight ? "50vh" : "100vh";
        const heroHeightVH = heights[this.heroHeight];
        $(".header-wrapper").addClass(this.heroHeight);

        
        const headerHeight = $(".header-wrapper").outerHeight();
        const breadcrumsHeight = $(".ui.vertical.breadcrumbs").outerHeight();
        const heroHeightCalculated = $(".heroBannerBlock").outerHeight();
        // const heroPosFormula = heroHeightCalculated + headerHeight + 8; // don't know why I need these +8 
        const heroPosFormula = heroHeightCalculated + breadcrumsHeight; // don't know why I need these +8 
        const breadcrumbsPosFormula = heroHeightCalculated - headerHeight;

        // console.log(`HeroHeight: ${heroHeightCalculated}, Header: ${headerHeight}, Bread: ${breadcrumsHeight}`);
        // console.log(`HeroPOS: ${heroPosFormula},  BreadPOS: ${breadcrumbsPosFormula}`);

        $(".ui.vertical.breadcrumbs").css("margin-top", `${breadcrumbsPosFormula}px`);
        if ($(".section-edit").length == 0) {
          // $(".heroBannerBlock").css("margin-top", `-${heroPosFormula}px`);
          $(".heroBannerBlock").css("margin-bottom", `${breadcrumsHeight}px`);  
        }

        if(this.fixedToTop) {
          $(".ui.pointing.secondary a.item").addClass("darkmode");
          $(".ui.pointing.secondary a.item").on("click", function() {
            $(".ui.pointing.secondary a.item").removeClass("darkmode");
          });
        }
      }  
    } catch (e) {
      // some issue with jquery
      console.log("EXCEPTION!!!! " + e);
    }
  }

  getColorSetting() {
    try {
      return this.props.data.blackAndWhite ? "blackAndWhite bgimage" : "color bgimage";
    } catch(e) {
      return "color";
    }
  }

  componentDidMount = () => {
    try {
      this.props.getContent(this.props.data.image[0]["@id"], null, this.props.data.image[0].UID, null, true);
    } catch (ex) {
      console.log("Component not yet configured with image data");
    }
    

  }

  componentDidUpdate(prevProps, prevState, snapshot) {

    try {
      if (prevProps.data.image !== this.props.data.image) {
        if (this.props.data.image!==null && this.props.data.image[0]!==undefined) {
            this.props.getContent(this.props.data.image[0]["@id"], null, this.props.data.image[0].UID, null, true);          
        }
      }
    } catch (ex) {
      console.log(ex);
    }
    
    // console.log(prevProps);
    // console.log(this.props);
  }



  render() {
    const imgUrl = () => {
      // console.log(JSON.stringify(this.props.data.image));
  
      let imagePath = "";
      try {
        const image = this.props.data.image[0].UID;
        imagePath = this.props.subrequests[image].data.image.download;
        // imagePath = this.props.data.image[0]["getPath"] + '/@@images/image.png';
      } catch (e) {
        console.log("Error in IMG URL in HeroBannerView: " + e.toString());
      }
  
      return 'url("' + imagePath + '")';
    }

    let link="";
    let hasLink=false;
    let linkClass="inactive";
    let linkText=""

    try {
      hasLink = (this.props.data.linkTarget !== undefined && this.props.data.linkTarget !== null && this.props.data.linkTarget[0] !== undefined) ? true : false;
      linkClass = hasLink ? "active" : "inactive";
      linkText = (this.props.data.linkText !== undefined && this.props.data.linkText !== null) ? this.props.data.linkText : "Learn more";
      link = hasLink ? this.props.data.linkTarget[0]["@id"] : "";
      // const link = "/";
    } catch {
      // silent 
    }
    

    // console.log(JSON.stringify(this.props.data.linkTarget[0], null, 2));

    return (
      <div className={this.blockClass}  >
        <div className="textBackgroundOverlay">
        </div>
        <div className="hero-text" >
          <div>
            <div className="noshadow padded-left">
              <h1>{this.heading}</h1>
              <h2 className="sub-heading">{this.subheading}</h2>
              <h2 className="highlight">{this.highlight}</h2>
              <div className="lede">{this.lede}</div>
            </div>
          </div>
        </div>
        <div className="hero-background" data-img={imgUrl()}>
          <div className={this.getColorSetting()} style={{backgroundImage : imgUrl()}}>
              <div className="readability-gradient"/>
          </div>  
          <div className="imageCredit">{this.props.data.imageCredit}</div>
        </div>
        <div className="scroll-hint">
        <div className="mouse-wheel"></div>
        </div>
        <div class={`hero-link ${linkClass}`}>
          <a href={`${link}`}>{linkText}</a>
        </div>  
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const propsStruct = {
    ...ownProps.data,
    subrequests: {
      ...state.content.subrequests
    },
  }

  // console.log(JSON.stringify(propsStruct,null, 2));

  return propsStruct;
}

const mapDispatchToProps = {
  getContent : getContent,
}

export default
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(HeroBannerView);

