/**
 * Gallery Images reducer.
 * @module reducers/content_types/reviews
 */

 import { GET_REVIEWS } from '../constants/ActionTypes';

 const initialState = {
   error: null,
   loaded: false,
   loading: true,
   products: []
 };

 /**
  * Faq reducer.
  * @function reviews
  * @param {Object} state Current state.
  * @param {Object} action Action to be handled.
  * @returns {Object} New state.
  */
 export default function reviews(state = initialState, action = {}) {
   switch (action.type) {
     case `${GET_REVIEWS}_PENDING`:
       return {
         ...state,
         error: "LOADING REVIEWS",
         loading: true,
         loaded: false,
         reviews: [],
       };
     case `${GET_REVIEWS}_SUCCESS`:
        // console.log(">>>>>>>>>>>>>>>>>>>>>>> GET_REVIEWS REDUCER <<<<<<<<<<<<<<<<<<<<<<<");
        // console.log(JSON.stringify(action.result, null, 2));
        const results = action.result.items.map((item) => {
          // const orderId = (item.orderId !== null) ? item.orderId.toLowerCase() : "";
          // const client = (item.parent["@id"]!=null) ? item.parent["@id"].split("/").slice(-1)[0] : "";

            return {
                uid: item.UID,
                // url: "order-"+orderId,
                // orderId: orderId,
                // title: item.title,
                description: item.description,
                // client: client,
                created: item.created,
                modified: item.modified,
                // status: item.status["title"],
            }
        });

        // console.log(JSON.stringify(results, null, 2));

        return {
            ...state,
            error: "DATA RECEIVED SUCCESSFULLY",
            loaded: true,
            loading: false,
            reviews: results
        };

     case `${GET_REVIEWS}_FAIL`:
       return {
         ...state,
         error: action.error,
         loading: false,
         loaded: false,
         reviews: [],
       };
     default:
       return state;
   }
 }
