/**
 * Root reducer.
 * @module reducers/root
 */

import defaultReducers from '@plone/volto/reducers';
import products from './products';
import orders from './orders';
import order_items from './order_items';
import pois from './pois';
import stores from './stores';
import images from './images';
import reviews from './reviews';
import email from './email';
/**
 * Root reducer.
 * @function
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
const reducers = {
  ...defaultReducers,
  products,
  orders, 
  order_items,
  pois,
  stores,
  images,
  reviews, 
  email
  // Add your reducers here
};

export default reducers;
