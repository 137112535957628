const IContactFormSchema = {
    title: 'Contact Form',

    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: [
        ],
      },
    ],

    properties: {
    },

    required: [],
  };

  export default IContactFormSchema;
