import React from 'react';
import { NavLink } from 'react-router-dom';
import { isInternalURL } from '@plone/volto/helpers';
import config from '@plone/volto/registry';

const NavItem = ({ item, lang }) => {
  const { settings } = config;
  // The item.url in the root is ''
  // TODO: make more consistent it everywhere (eg. reducers to return '/' instead of '')

  // GLISCO: THIS IS SUB OPTIMAL. 
  // Links should be displayed in the Nav, but they are not. Probably a Volto issue.
  // This might be related to Links not having an adapter with the name "portal_tabs_view".
  // May the best way of doing this is to change our OWL Plone Package to include such 
  // an adapter, and no need to change the nav item in the way that we do here. 

  // But the strategy below is reasonable and works. It's a quick hack with hardcoded URLs!!!

  const storeUrl = "/owlstore";

  const isStoreUrl = (url) => {
      return (url==='/en/owl_store' || url==='/pt/owl_loja' );
  }

  const externalUrlAdapter = (url) => isStoreUrl(url) ? storeUrl : url;

  if ((isInternalURL(item.url) || item.url === '') && !isStoreUrl(item.url)) {
    return (
      <NavLink
        to={item.url === '' ? '/' : item.url}
        key={item.url}
        className="item"
        activeClassName="active"
        exact={
          settings.isMultilingual ? item.url === `/${lang}` : item.url === ''
        }
      >
        {item.title}
      </NavLink>
    );
  } else {
    return (
      <a
        href={externalUrlAdapter(item.url)}
        key={item.url}
        className="item"
        rel="noopener noreferrer"
      >
        {item.title}
      </a>
    );
  }
};

export default NavItem;
